// This file is compiled inside Decidim core pack. Code can be added here and will be executed
// as part of that pack

// Load images
require.context('../../images', true);

window.addEventListener(
  'load',
  () => {
    const paragraph = document.querySelectorAll('.paragraph__hover-section');
    paragraph.forEach((para) => {
      const paraContent = para.querySelector('.paragraph_content');
      const paraOpeners = para.querySelectorAll('.paragraph_opener');
      paraOpeners.forEach((opener) => {
        opener.addEventListener('click', () => {
          paraContent.classList.toggle('open');
          paraOpeners.forEach((item) => {
            item.classList.toggle('active');
          });
        });
      });
    });
  },
  false
);
